import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`On Monday, April 29th, students set up tents on the university lawn and are calling for the University of San Francisco to divest from its relationship with the state of Israel.`}</p>
    <p>{`Seeing a tip from my network, I went up to the University of San Francisco to observe a planned student walkout. As of 11:50 there were around a dozen students on the lawn, laying out in the sun and sitting on chairs, enjoying the weather.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px",
          "marginBottom": "1rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.87116564417178%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEA//EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAABTeZYVEYs/8QAGhAAAgIDAAAAAAAAAAAAAAAAAQIAAxIgMv/aAAgBAQABBQKjksgmS6f/xAAXEQADAQAAAAAAAAAAAAAAAAAAARES/9oACAEDAQE/AYjKP//EABcRAQEBAQAAAAAAAAAAAAAAAAEAAxP/2gAIAQIBAT8BNG6t/8QAGBAAAgMAAAAAAAAAAAAAAAAAADEBICH/2gAIAQEABj8C0Y4p/8QAHBABAAEEAwAAAAAAAAAAAAAAAQARITGBQWHx/9oACAEBAAE/IRc4cTtrgYKSFChbUSvk3P/aAAwDAQACAAMAAAAQDM//xAAXEQEAAwAAAAAAAAAAAAAAAAABEBEh/9oACAEDAQE/EFtqL//EABcRAQADAAAAAAAAAAAAAAAAAAABIWH/2gAIAQIBAT8QgUwf/8QAHBABAQACAgMAAAAAAAAAAAAAAREAMSFRYXGR/9oACAEBAAE/EOb22jejnJAkKglymA7LrKZB4BibT4xE1Hoz/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "lawn",
          "title": "lawn",
          "src": "/static/8b979142b036ab08e4780c1b7cf7bce5/6aca1/20240428-IMG_5921.jpg",
          "srcSet": ["/static/8b979142b036ab08e4780c1b7cf7bce5/d2f63/20240428-IMG_5921.jpg 163w", "/static/8b979142b036ab08e4780c1b7cf7bce5/c989d/20240428-IMG_5921.jpg 325w", "/static/8b979142b036ab08e4780c1b7cf7bce5/6aca1/20240428-IMG_5921.jpg 650w", "/static/8b979142b036ab08e4780c1b7cf7bce5/7c09c/20240428-IMG_5921.jpg 975w", "/static/8b979142b036ab08e4780c1b7cf7bce5/01ab0/20240428-IMG_5921.jpg 1300w", "/static/8b979142b036ab08e4780c1b7cf7bce5/bd53b/20240428-IMG_5921.jpg 2250w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`At noon, I heard some shouts and cheers in the distance. I found the crowd gathered in front of the cafeteria, with megaphones, surrounded by organizers wearing high vis vests.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px",
          "marginBottom": "1rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.87116564417178%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAf/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAHZWKKoQD//xAAaEAADAQADAAAAAAAAAAAAAAABAhEDACEi/9oACAEBAAEFAnlvYjcz0KqzElPY/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Bp//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/AYj/xAAdEAACAQQDAAAAAAAAAAAAAAAAAREQEiEiQWGB/9oACAEBAAY/AtHKpOfC1cj6JbP/xAAaEAADAAMBAAAAAAAAAAAAAAAAAREhMVGB/9oACAEBAAE/IaS6VbYp2hFkoWfZwpD61wR78f/aAAwDAQACAAMAAAAQVN//xAAWEQEBAQAAAAAAAAAAAAAAAAAAMUH/2gAIAQMBAT8QLj//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREh/9oACAECAQE/EJdMH//EABwQAQACAwEBAQAAAAAAAAAAAAEAESExQaFRYf/aAAgBAQABPxBtOe5A+QAyS0CQhSHd0H9gdYJm5C689hYdDQWtgb5M/V6xZw4M/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "a crowd of protesters, carrying signs and many wearing kufiya",
          "title": "a crowd of protesters, carrying signs and many wearing kufiya",
          "src": "/static/df02f89530ec5b915c4d6c8ca7db2ed6/6aca1/20240429-IMG_5925.jpg",
          "srcSet": ["/static/df02f89530ec5b915c4d6c8ca7db2ed6/d2f63/20240429-IMG_5925.jpg 163w", "/static/df02f89530ec5b915c4d6c8ca7db2ed6/c989d/20240429-IMG_5925.jpg 325w", "/static/df02f89530ec5b915c4d6c8ca7db2ed6/6aca1/20240429-IMG_5925.jpg 650w", "/static/df02f89530ec5b915c4d6c8ca7db2ed6/7c09c/20240429-IMG_5925.jpg 975w", "/static/df02f89530ec5b915c4d6c8ca7db2ed6/01ab0/20240429-IMG_5925.jpg 1300w", "/static/df02f89530ec5b915c4d6c8ca7db2ed6/a1747/20240429-IMG_5925.jpg 3456w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`A speaker from the law school spoke about inadequate student housing and pay for professors, in contrast with the university's financial entanglements with the state of Israel.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px",
          "marginBottom": "1rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.87116564417178%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDAf/EABYBAQEBAAAAAAAAAAAAAAAAAAEAAv/aAAwDAQACEAMQAAAB2SgqWLP/xAAaEAACAwEBAAAAAAAAAAAAAAABEgACAxEi/9oACAEBAAEFAn4Dcz3Mx3Slm1VZ/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAECEf/aAAgBAwEBPwGW1f/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABoQAAICAwAAAAAAAAAAAAAAAAARAQIQMUH/2gAIAQEABj8CzIrQ+C2f/8QAHRABAAICAgMAAAAAAAAAAAAAAQARMWEhQXGBkf/aAAgBAQABPyEod+4Qca8QWWX9JSlh1nhBmJTU/9oADAMBAAIAAwAAABAr/wD/xAAXEQEBAQEAAAAAAAAAAAAAAAABACER/9oACAEDAQE/EAGXDG//xAAXEQEAAwAAAAAAAAAAAAAAAAAAESFB/9oACAECAQE/EI1b/8QAHRABAAICAgMAAAAAAAAAAAAAAQARITFBUWFx0f/aAAgBAQABPxBSgWM4ASui+wN328wFZHr6S7GtJ3zKhIQXYXh9kp5gaXjc/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "a speaker, engaging the crowd",
          "title": "a speaker, engaging the crowd",
          "src": "/static/a0057c66429ad58c3863c744e8b3b33b/6aca1/20240429-IMG_5932.jpg",
          "srcSet": ["/static/a0057c66429ad58c3863c744e8b3b33b/d2f63/20240429-IMG_5932.jpg 163w", "/static/a0057c66429ad58c3863c744e8b3b33b/c989d/20240429-IMG_5932.jpg 325w", "/static/a0057c66429ad58c3863c744e8b3b33b/6aca1/20240429-IMG_5932.jpg 650w", "/static/a0057c66429ad58c3863c744e8b3b33b/7c09c/20240429-IMG_5932.jpg 975w", "/static/a0057c66429ad58c3863c744e8b3b33b/01ab0/20240429-IMG_5932.jpg 1300w", "/static/a0057c66429ad58c3863c744e8b3b33b/a1747/20240429-IMG_5932.jpg 3456w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`The speaker also detailed the group's demands:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Condemn Israeli occupation's genocide of Palestinians`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`USF must immediately disclose and divest from all Israeli occupation affiliated endowments and investments`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`USF must end all academic partnerships with the Israeli occupation`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`USF must protect pro-Palestine speach and students on campus`}</p>
      </li>
    </ol>
    <p>{`At 12:26, the crowd began a march, advancing to the lawn.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px",
          "marginBottom": "1rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.87116564417178%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQBAwUG/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQAC/9oADAMBAAIQAxAAAAGE+koLDHDR/8QAGhAAAwADAQAAAAAAAAAAAAAAAAECAxESIv/aAAgBAQABBQKq2cs5ocJmbGnDXr//xAAVEQEBAAAAAAAAAAAAAAAAAAAAIf/aAAgBAwEBPwFH/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8BR//EABgQAAMBAQAAAAAAAAAAAAAAAAABIRAi/9oACAEBAAY/ApCs5pcZ/8QAHBAAAgMAAwEAAAAAAAAAAAAAAREAITFBUWGR/9oACAEBAAE/IS0F11zCfezAgIny5jq6lUACvIq87wV5P//aAAwDAQACAAMAAAAQQO//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPxBT/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAECAQE/EAAyL//EABsQAQACAgMAAAAAAAAAAAAAAAERIQBBMVFx/9oACAEBAAE/EFL+plHo1jwKgZ3LxMuImALUpsZs0RSjIQ+yVpju8PcWcCuh5n//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "students marching to the lawn. the front of the crowd",
          "title": "students marching to the lawn. the front of the crowd",
          "src": "/static/c0ce10b5445cc49a7b6895a875ea3720/6aca1/20240429-IMG_5938.jpg",
          "srcSet": ["/static/c0ce10b5445cc49a7b6895a875ea3720/d2f63/20240429-IMG_5938.jpg 163w", "/static/c0ce10b5445cc49a7b6895a875ea3720/c989d/20240429-IMG_5938.jpg 325w", "/static/c0ce10b5445cc49a7b6895a875ea3720/6aca1/20240429-IMG_5938.jpg 650w", "/static/c0ce10b5445cc49a7b6895a875ea3720/7c09c/20240429-IMG_5938.jpg 975w", "/static/c0ce10b5445cc49a7b6895a875ea3720/01ab0/20240429-IMG_5938.jpg 1300w", "/static/c0ce10b5445cc49a7b6895a875ea3720/a1747/20240429-IMG_5938.jpg 3456w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px",
          "marginBottom": "1rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "150.30674846625766%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGQAAAwADAAAAAAAAAAAAAAAAAAIFAQME/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQIA/9oADAMBAAIQAxAAAAFkXTOwKUVp9qXmedIz/8QAHRAAAgICAwEAAAAAAAAAAAAAAQIAAxESBBMhMf/aAAgBAQABBQLGpawJDdD9dJos6zvyciLawF5wvlrpUpH/xAAXEQADAQAAAAAAAAAAAAAAAAAAEBES/9oACAEDAQE/AY9H/8QAGREAAgMBAAAAAAAAAAAAAAAAAAEQERIh/9oACAECAQE/AVddMuMn/8QAHRAAAgIDAAMAAAAAAAAAAAAAAAERIQISMRBBgf/aAAgBAQAGPwJeYy6jZ4lo2ghnUTfwSU1djr2f/8QAHBAAAwADAQEBAAAAAAAAAAAAAAERITFBUWGR/9oACAEBAAE/IXuquM3ir8H3EK69CK1iYzSWxXI/jUkuCea+r0nkCL/0g3NKpqtwXgP/2gAMAwEAAgADAAAAELvLP//EABgRAAMBAQAAAAAAAAAAAAAAAAABESEx/9oACAEDAQE/EOMG4LCz/8QAGREAAgMBAAAAAAAAAAAAAAAAAAEQESFx/9oACAECAQE/EOwJmlx//8QAGxABAAMBAQEBAAAAAAAAAAAAAQARIUExUYH/2gAIAQEAAT8Qp51fSLoil0Pf2UFvG3ZGxq2o1Mu+eVH7HJHWggK9ABtovJdfXb8a1Fxq4OJL38YnEFXVIGU016od6Z9zBHy5EG8on//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "students marching to the lawn. the middle of the crowd",
          "title": "students marching to the lawn. the middle of the crowd",
          "src": "/static/947e36e5f10f4c4fb01d08d53f9e103b/6aca1/20240429-IMG_5945.jpg",
          "srcSet": ["/static/947e36e5f10f4c4fb01d08d53f9e103b/d2f63/20240429-IMG_5945.jpg 163w", "/static/947e36e5f10f4c4fb01d08d53f9e103b/c989d/20240429-IMG_5945.jpg 325w", "/static/947e36e5f10f4c4fb01d08d53f9e103b/6aca1/20240429-IMG_5945.jpg 650w", "/static/947e36e5f10f4c4fb01d08d53f9e103b/7c09c/20240429-IMG_5945.jpg 975w", "/static/947e36e5f10f4c4fb01d08d53f9e103b/01ab0/20240429-IMG_5945.jpg 1300w", "/static/947e36e5f10f4c4fb01d08d53f9e103b/ae289/20240429-IMG_5945.jpg 2304w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px",
          "marginBottom": "1rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.87116564417178%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQBAwUG/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQAC/9oADAMBAAIQAxAAAAGE+koLDHDR/8QAGhAAAwADAQAAAAAAAAAAAAAAAAECAxESIv/aAAgBAQABBQKq2cs5ocJmbGnDXr//xAAVEQEBAAAAAAAAAAAAAAAAAAAAIf/aAAgBAwEBPwFH/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8BR//EABgQAAMBAQAAAAAAAAAAAAAAAAABIRAi/9oACAEBAAY/ApCs5pcZ/8QAHBAAAgMAAwEAAAAAAAAAAAAAAREAITFBUWGR/9oACAEBAAE/IS0F11zCfezAgIny5jq6lUACvIq87wV5P//aAAwDAQACAAMAAAAQQO//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPxBT/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAECAQE/EAAyL//EABsQAQACAgMAAAAAAAAAAAAAAAERIQBBMVFx/9oACAEBAAE/EFL+plHo1jwKgZ3LxMuImALUpsZs0RSjIQ+yVpju8PcWcCuh5n//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "students marching to the lawn. the front of the crowd",
          "title": "students marching to the lawn. the front of the crowd",
          "src": "/static/c0ce10b5445cc49a7b6895a875ea3720/6aca1/20240429-IMG_5938.jpg",
          "srcSet": ["/static/c0ce10b5445cc49a7b6895a875ea3720/d2f63/20240429-IMG_5938.jpg 163w", "/static/c0ce10b5445cc49a7b6895a875ea3720/c989d/20240429-IMG_5938.jpg 325w", "/static/c0ce10b5445cc49a7b6895a875ea3720/6aca1/20240429-IMG_5938.jpg 650w", "/static/c0ce10b5445cc49a7b6895a875ea3720/7c09c/20240429-IMG_5938.jpg 975w", "/static/c0ce10b5445cc49a7b6895a875ea3720/01ab0/20240429-IMG_5938.jpg 1300w", "/static/c0ce10b5445cc49a7b6895a875ea3720/a1747/20240429-IMG_5938.jpg 3456w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px",
          "marginBottom": "1rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "150.30674846625766%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAADAv/EABYBAQEBAAAAAAAAAAAAAAAAAAACA//aAAwDAQACEAMQAAABy1H2Pj2TFCV6CSap/8QAGxAAAgIDAQAAAAAAAAAAAAAAAQIAAxETIhL/2gAIAQEAAQUCSxcYrMPkxiNhaKWsAr7pqbWq64KoiKC46//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABkRAAEFAAAAAAAAAAAAAAAAAAABAxARYf/aAAgBAgEBPwGhW9n/xAAhEAABAgQHAAAAAAAAAAAAAAAAARECEDFBAxIhQnGSwf/aAAgBAQAGPwKpWSWRdT0dMNyNrHJlNvUdEpL/xAAdEAEAAwEAAgMAAAAAAAAAAAABABEhMUFRcYGh/9oACAEBAAE/ITAYo20+5c6NFSih+FM7NCjwjA5vpM55gtJYGNvcUtjqxL1R8IcCPEuc/9oADAMBAAIAAwAAABDTNP8A/8QAGBEAAgMAAAAAAAAAAAAAAAAAACEBEDH/2gAIAQMBAT8QQqnT/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERMf/aAAgBAgEBPxC9Qxu0UR//xAAcEAEBAAMAAwEAAAAAAAAAAAABEQAhMUFxkVH/2gAIAQEAAT8Q7pmRNZvEaoEUwnkZVGTIJJsNynPd3hUC1IEtfGUigIAX8xG8Ten76XmObQ2pXTMQQQmjYunAVViCT9POCRyINmEiHDuf/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "students marching to the lawn. the tail end of the crowd",
          "title": "students marching to the lawn. the tail end of the crowd",
          "src": "/static/114fae7cc5e8127fb52a6f9c7a8eecd8/6aca1/20240429-IMG_5950.jpg",
          "srcSet": ["/static/114fae7cc5e8127fb52a6f9c7a8eecd8/d2f63/20240429-IMG_5950.jpg 163w", "/static/114fae7cc5e8127fb52a6f9c7a8eecd8/c989d/20240429-IMG_5950.jpg 325w", "/static/114fae7cc5e8127fb52a6f9c7a8eecd8/6aca1/20240429-IMG_5950.jpg 650w", "/static/114fae7cc5e8127fb52a6f9c7a8eecd8/7c09c/20240429-IMG_5950.jpg 975w", "/static/114fae7cc5e8127fb52a6f9c7a8eecd8/01ab0/20240429-IMG_5950.jpg 1300w", "/static/114fae7cc5e8127fb52a6f9c7a8eecd8/ae289/20240429-IMG_5950.jpg 2304w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`As they marched, the students chanted "disclose, divest, we will not stop, we will not rest". They promptly formed a circle and began setting up tents once they reached the lawn.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"disclose, divest, we will not stop, we will not rest"`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px",
          "marginBottom": "1rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.87116564417178%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBP/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABWdzEQyh//8QAGxABAAICAwAAAAAAAAAAAAAAAgEhAAMREhP/2gAIAQEAAQUC9OFbgmsVztXVf//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/Aaf/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwGo/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAEQERIhMf/aAAgBAQAGPwLiMioTWikf/8QAGhAAAgMBAQAAAAAAAAAAAAAAAREAITFRYf/aAAgBAQABPyFwBQTFQJUeLIpFAVuxApgV5AKAQCxwwqQuz//aAAwDAQACAAMAAAAQW/8A/8QAFxEBAAMAAAAAAAAAAAAAAAAAABFBgf/aAAgBAwEBPxCVsf/EABgRAQEAAwAAAAAAAAAAAAAAAAEAESFB/9oACAECAQE/EA9snZf/xAAcEAEBAAMBAQEBAAAAAAAAAAABEQAhMUGRUXH/2gAIAQEAAT8QDBFJs6L9yhSmA0+kP57ibVsQT9P25KNldYRG+PY1wec8ypQnQp1z/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "students assembling tents",
          "title": "students assembling tents",
          "src": "/static/e36380cb50c49463ae258b69a7098892/6aca1/20240429-IMG_5956.jpg",
          "srcSet": ["/static/e36380cb50c49463ae258b69a7098892/d2f63/20240429-IMG_5956.jpg 163w", "/static/e36380cb50c49463ae258b69a7098892/c989d/20240429-IMG_5956.jpg 325w", "/static/e36380cb50c49463ae258b69a7098892/6aca1/20240429-IMG_5956.jpg 650w", "/static/e36380cb50c49463ae258b69a7098892/7c09c/20240429-IMG_5956.jpg 975w", "/static/e36380cb50c49463ae258b69a7098892/01ab0/20240429-IMG_5956.jpg 1300w", "/static/e36380cb50c49463ae258b69a7098892/a1747/20240429-IMG_5956.jpg 3456w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px",
          "marginBottom": "1rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.87116564417178%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAgP/xAAVAQEBAAAAAAAAAAAAAAAAAAACAP/aAAwDAQACEAMQAAABYopMMgCv/8QAGxAAAgMAAwAAAAAAAAAAAAAAAQIAAxESEyH/2gAIAQEAAQUCFqxrwIzjlmzNPX7/AP/EABYRAAMAAAAAAAAAAAAAAAAAAAEQEv/aAAgBAwEBPwGQv//EABYRAQEBAAAAAAAAAAAAAAAAAAARE//aAAgBAgEBPwHRX//EABoQAQADAAMAAAAAAAAAAAAAAAABITECQZH/2gAIAQEABj8C78VxS2Vtf//EABoQAAIDAQEAAAAAAAAAAAAAAAABESFBcWH/2gAIAQEAAT8hhuUGgdHHVY3aXyNhuasdns//2gAMAwEAAgADAAAAELQ//8QAFxEBAAMAAAAAAAAAAAAAAAAAAAERYf/aAAgBAwEBPxDFcP/EABYRAAMAAAAAAAAAAAAAAAAAAAARUf/aAAgBAgEBPxB6LT//xAAeEAEAAgIBBQAAAAAAAAAAAAABESEAMUFRYXGB4f/aAAgBAQABPxBDU+43o85ACp6oyvKUxeSPBZvTm6rAOxL9zmTYs9Z//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "students forming a ring around the tents",
          "title": "students forming a ring around the tents",
          "src": "/static/4effdc95866da4a738864c6c188f3add/6aca1/20240429-IMG_5961.jpg",
          "srcSet": ["/static/4effdc95866da4a738864c6c188f3add/d2f63/20240429-IMG_5961.jpg 163w", "/static/4effdc95866da4a738864c6c188f3add/c989d/20240429-IMG_5961.jpg 325w", "/static/4effdc95866da4a738864c6c188f3add/6aca1/20240429-IMG_5961.jpg 650w", "/static/4effdc95866da4a738864c6c188f3add/7c09c/20240429-IMG_5961.jpg 975w", "/static/4effdc95866da4a738864c6c188f3add/01ab0/20240429-IMG_5961.jpg 1300w", "/static/4effdc95866da4a738864c6c188f3add/a1747/20240429-IMG_5961.jpg 3456w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`At 12:45 the students hung a list of their demands on a tree near the encampment.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px",
          "marginBottom": "1rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "150.30674846625766%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEBQH/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAWITXHSUTWhj4a5iGH//xAAbEAACAgMBAAAAAAAAAAAAAAABAgMSABEyE//aAAgBAQABBQKlI64ABiyMXfgWQNqr8hEYe+GfYEzqP//EABURAQEAAAAAAAAAAAAAAAAAABEg/9oACAEDAQE/AWP/xAAYEQEAAwEAAAAAAAAAAAAAAAAAAhESYf/aAAgBAgEBPwFaMcs9f//EAB0QAAIDAAIDAAAAAAAAAAAAAAABAhEhAxAyYXH/2gAIAQEABj8CcWrtmxwzjUvdnHZL4V0xOhZqPEpM/8QAGxAAAgMBAQEAAAAAAAAAAAAAAREAITFBUaH/2gAIAQEAAT8hCu4FGAAAQ+RoBj1AFOlFG0WTty9QfJjXIej17DUWZ7UMQJdbBCgMn//aAAwDAQACAAMAAAAQfzaz/8QAFxEBAQEBAAAAAAAAAAAAAAAAAREAEP/aAAgBAwEBPxAgmmR5/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQAREP/aAAgBAgEBPxA5AJtuf//EAB4QAQACAwEAAwEAAAAAAAAAAAEAESExQVFhcYHB/9oACAEBAAE/EHG0KLSZ/kc6ZXR1uVVVYGfy8fUYzeh87lgvZWIyKDxfYLBWoVab4yyayvsEg05U8gBnZY1b7UqGUU26SyoW3DzU/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "a sign reading: 1. Condemn Israeli occupation's genocide of Palestinians 2. USF must immediately disclose and divest from all Israeli occupation affiliated endowments and investments 3. USF must end all academic partnerships with the Israeli occupation 4. USF must protect pro-Palestine speach and students on campus",
          "title": "a sign reading: 1. Condemn Israeli occupation's genocide of Palestinians 2. USF must immediately disclose and divest from all Israeli occupation affiliated endowments and investments 3. USF must end all academic partnerships with the Israeli occupation 4. USF must protect pro-Palestine speach and students on campus",
          "src": "/static/e827a3d07a2390694e18a253cec12566/6aca1/20240429-IMG_5975.jpg",
          "srcSet": ["/static/e827a3d07a2390694e18a253cec12566/d2f63/20240429-IMG_5975.jpg 163w", "/static/e827a3d07a2390694e18a253cec12566/c989d/20240429-IMG_5975.jpg 325w", "/static/e827a3d07a2390694e18a253cec12566/6aca1/20240429-IMG_5975.jpg 650w", "/static/e827a3d07a2390694e18a253cec12566/7c09c/20240429-IMG_5975.jpg 975w", "/static/e827a3d07a2390694e18a253cec12566/01ab0/20240429-IMG_5975.jpg 1300w", "/static/e827a3d07a2390694e18a253cec12566/ae289/20240429-IMG_5975.jpg 2304w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Around 200 students were gathered. Several speeches were delivered, with personal experiences of the impact of Israel's occupation delivered. Some people began to arrive with food and other resources for the crowd.`}</p>
    <p>{`At 1pm, a call went out for muslim students to come forward and pray.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px",
          "marginBottom": "1rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.87116564417178%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQT/xAAWAQEBAQAAAAAAAAAAAAAAAAACAAH/2gAMAwEAAhADEAAAAZrkzdgpi//EABsQAAICAwEAAAAAAAAAAAAAAAECAAMREyJB/9oACAEBAAEFAmM8KSvqFzmurYn/xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8BjI//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwGq/8QAGxAAAgIDAQAAAAAAAAAAAAAAAAERIRIyUQL/2gAIAQEABj8CreYZTpUbsyfBLtC9ZH//xAAaEAEBAQADAQAAAAAAAAAAAAABEQAhMUFh/9oACAEBAAE/IaSEUnY/dIlFlDxgHpfY4qtyRnhZ3Z+EXyb/2gAMAwEAAgADAAAAEHw//8QAFxEAAwEAAAAAAAAAAAAAAAAAAAFBEf/aAAgBAwEBPxBlTSn/xAAXEQADAQAAAAAAAAAAAAAAAAAAAREh/9oACAECAQE/EE10tZD/xAAgEAEAAgEDBQEAAAAAAAAAAAABABExIUFRYXGBkaHx/9oACAEBAAE/EKDspNThFe4WFWYsO4u9wMWANBFrOeKmBgkDrn8j2AkXUTt4jD0XL9uf/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "students decorate signs while others lay out rugs for prayer",
          "title": "students decorate signs while others lay out rugs for prayer",
          "src": "/static/efd9105c0493f59a281d84fe22a7ac23/6aca1/20240429-IMG_5976.jpg",
          "srcSet": ["/static/efd9105c0493f59a281d84fe22a7ac23/d2f63/20240429-IMG_5976.jpg 163w", "/static/efd9105c0493f59a281d84fe22a7ac23/c989d/20240429-IMG_5976.jpg 325w", "/static/efd9105c0493f59a281d84fe22a7ac23/6aca1/20240429-IMG_5976.jpg 650w", "/static/efd9105c0493f59a281d84fe22a7ac23/7c09c/20240429-IMG_5976.jpg 975w", "/static/efd9105c0493f59a281d84fe22a7ac23/01ab0/20240429-IMG_5976.jpg 1300w", "/static/efd9105c0493f59a281d84fe22a7ac23/a1747/20240429-IMG_5976.jpg 3456w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`I left to go eat, and returned at 1:42. At that time, the camp was still peaceful, and there were no signs of university or police presence. They declared the camp open, and instructed everyone who participates in the demonstration to abide by their community guidelines.`}</p>
    <p>{`They had roped off the area they decided to occupy, and a band began playing Killing in the Name of by Rage Against the Machine`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px",
          "marginBottom": "1rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.87116564417178%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMFAgT/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAcLouzJp0h//xAAbEAEAAgIDAAAAAAAAAAAAAAABAAMSEwIRIv/aAAgBAQABBQLBJr6dfNhUEx8tM//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/Aar/xAAeEAAABAcAAAAAAAAAAAAAAAAAARAxAgMRISKBof/aAAgBAQAGPwLGPiPL2aUO4cf/xAAdEAACAgIDAQAAAAAAAAAAAAABEQBBITFRYXHR/9oACAEBAAE/IUkIhaOUGYz6pwarIU2xErO4En5n/9oADAMBAAIAAwAAABCk7//EABcRAAMBAAAAAAAAAAAAAAAAAAABESH/2gAIAQMBAT8QyFR//8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8QlL//xAAcEAEBAAMAAwEAAAAAAAAAAAABEQAhMUFRkaH/2gAIAQEAAT8QidZdKb148OGIsponr3JB0LCHXrNle0ibj3eEVh5VT18w1GAQh/c//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "the roped off area",
          "title": "the roped off area",
          "src": "/static/fffd39eb20c7dacea2424e8a3dc05948/6aca1/20240429-IMG_5987.jpg",
          "srcSet": ["/static/fffd39eb20c7dacea2424e8a3dc05948/d2f63/20240429-IMG_5987.jpg 163w", "/static/fffd39eb20c7dacea2424e8a3dc05948/c989d/20240429-IMG_5987.jpg 325w", "/static/fffd39eb20c7dacea2424e8a3dc05948/6aca1/20240429-IMG_5987.jpg 650w", "/static/fffd39eb20c7dacea2424e8a3dc05948/7c09c/20240429-IMG_5987.jpg 975w", "/static/fffd39eb20c7dacea2424e8a3dc05948/01ab0/20240429-IMG_5987.jpg 1300w", "/static/fffd39eb20c7dacea2424e8a3dc05948/a1747/20240429-IMG_5987.jpg 3456w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px",
          "marginBottom": "1rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.87116564417178%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDAf/EABYBAQEBAAAAAAAAAAAAAAAAAAEAAv/aAAwDAQACEAMQAAABXZ0zTHA//8QAGBABAQEBAQAAAAAAAAAAAAAAAQARAjL/2gAIAQEAAQUC89rlhciTrbf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAWEQEBAQAAAAAAAAAAAAAAAAAAERL/2gAIAQIBAT8BrT//xAAbEAADAAIDAAAAAAAAAAAAAAAAARECMhASIf/aAAgBAQAGPwJZe2QSNWbF7Pj/xAAbEAADAQADAQAAAAAAAAAAAAAAARExIVFhgf/aAAgBAQABPyGQU/A6akH7/YhuevwTbcwV+gs//9oADAMBAAIAAwAAABC0L//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPxBkf//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/EIU//8QAHRABAAMAAQUAAAAAAAAAAAAAAQARITFBYXGBkf/aAAgBAQABPxBomb3JNO+Rm9Olls1E91EPFavEL4moQxW7+wMyO308VEOQWf/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "a band playing Killing in the Name of",
          "title": "a band playing Killing in the Name of",
          "src": "/static/fe6d10e01b3ab73b4a7d8e27195276d8/6aca1/20240429-IMG_5986.jpg",
          "srcSet": ["/static/fe6d10e01b3ab73b4a7d8e27195276d8/d2f63/20240429-IMG_5986.jpg 163w", "/static/fe6d10e01b3ab73b4a7d8e27195276d8/c989d/20240429-IMG_5986.jpg 325w", "/static/fe6d10e01b3ab73b4a7d8e27195276d8/6aca1/20240429-IMG_5986.jpg 650w", "/static/fe6d10e01b3ab73b4a7d8e27195276d8/7c09c/20240429-IMG_5986.jpg 975w", "/static/fe6d10e01b3ab73b4a7d8e27195276d8/01ab0/20240429-IMG_5986.jpg 1300w", "/static/fe6d10e01b3ab73b4a7d8e27195276d8/a1747/20240429-IMG_5986.jpg 3456w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`The atmosphere by 2pm was positive and relaxed. It is unclear how long the protest will go on at this time, or how the university will respond.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px",
          "marginBottom": "1rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.87116564417178%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBP/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABTVBmJlBf/8QAGxAAAQQDAAAAAAAAAAAAAAAAAAECERMDEiH/2gAIAQEAAQUCwKWNjimhWVSf/8QAFhEAAwAAAAAAAAAAAAAAAAAAAAES/9oACAEDAQE/AZTJP//EABYRAQEBAAAAAAAAAAAAAAAAAAASAf/aAAgBAgEBPwG9W//EABkQAAIDAQAAAAAAAAAAAAAAAAABETEykf/aAAgBAQAGPwJyKUy3ws0aP//EABoQAQEBAAMBAAAAAAAAAAAAAAERADFRYYH/2gAIAQEAAT8hMntxioC5FU2K5+pqZ08zSq3/2gAMAwEAAgADAAAAEBzv/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAEDAQE/EF2pZv/EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQIBAT8QC4Nq/8QAGxABAQEBAAMBAAAAAAAAAAAAAREAIXGRsaH/2gAIAQEAAT8Q6JrRXuY6JsgWJiqq94foZgdGIrp/dYhCrWvru8j43//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "the same establishing shot of the lawn, now occupied",
          "title": "the same establishing shot of the lawn, now occupied",
          "src": "/static/cb89d70289d0e4b62a9cec215c7ea5ce/6aca1/20240429-IMG_5988.jpg",
          "srcSet": ["/static/cb89d70289d0e4b62a9cec215c7ea5ce/d2f63/20240429-IMG_5988.jpg 163w", "/static/cb89d70289d0e4b62a9cec215c7ea5ce/c989d/20240429-IMG_5988.jpg 325w", "/static/cb89d70289d0e4b62a9cec215c7ea5ce/6aca1/20240429-IMG_5988.jpg 650w", "/static/cb89d70289d0e4b62a9cec215c7ea5ce/7c09c/20240429-IMG_5988.jpg 975w", "/static/cb89d70289d0e4b62a9cec215c7ea5ce/01ab0/20240429-IMG_5988.jpg 1300w", "/static/cb89d70289d0e4b62a9cec215c7ea5ce/a1747/20240429-IMG_5988.jpg 3456w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      